import parse from '@customink/html-react-parser';
import {Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import ProductContext from 'product_details_page/Context/productContext';
import ProductDetailsRow from 'product_details_page/ProductDetailsRow';

export default function ProductDescription() {
  const {description} = useContext(ProductContext);

  if (!description) {
    return null;
  }

  return (
    <ProductDetailsRow
      title="Product Description"
      defaultExpanded
      accordionSummary="Product Description"
      accordionWrapperSx={{px: 0}}
      useAccordionAlways>
      <Typography variant="bodyLong1">{parse(description)}</Typography>
    </ProductDetailsRow>
  );
}
