import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  MinusCircleIcon,
  PlusCircleIcon,
  Select,
  Stack,
  TextField
} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import React, {useContext, useEffect, useState} from 'react';
import CurrentSelectedColorContext from './Context/colorContext';

const InputGroup = styled(FormControl)({
  px: {md: '1rem', sm: 0},
  width: '100%'
});

const EmployeeBlanks = ({availableSizes, currentColor}) => {
  const {currentSelectedColor} = useContext(CurrentSelectedColorContext);
  const sizeList = availableSizes.map((size) => size.conciseName);
  const initialBlanksQuantity = 1;
  const [blanksQuantity, setBlanksQuantity] = useState(initialBlanksQuantity);
  const [selectedSize, setSelectedSize] = useState(sizeList[0]);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (!sizeList.includes(selectedSize)) {
      setSelectedSize(sizeList[0]);
    }
  }, [currentSelectedColor]);

  const handleChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const onAddToCart = () => {
    StyleBitz.Cart.addColor(currentColor.id, selectedSize, blanksQuantity);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
    }, 5000);
  };

  const sizes = currentColor.sizes.filter((size) => size.status === 'active');
  return (
    <Stack width="100%" gap="1rem" py="1rem">
      <Box
        sx={{
          display: 'flex',
          flexDirection: {md: 'row', sm: 'column'},
          gap: '1rem',
          justifyItems: 'center',
          width: '100%'
        }}>
        <InputGroup sx={{flex: 1.5}}>
          <InputLabel id="blanks-size-select-label">Size</InputLabel>
          <Select
            value={selectedSize}
            labelId="blanks-size-select-label"
            id="blanks-size-select"
            onChange={(e) => handleChange(e)}
            data-testid="blanks-size-select">
            {sizes.map((size) => (
              <MenuItem
                key={`blanks-size-${size.name}`}
                value={size.conciseName}>
                {size.conciseName}
              </MenuItem>
            ))}
          </Select>
        </InputGroup>
        <InputGroup sx={{flex: 1}}>
          <InputLabel id="blanks-qty-label">Qty.</InputLabel>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              width: '100%'
            }}>
            <IconButton
              size="large"
              color="blue"
              sx={{ml: '-0.5rem', px: 0}}
              data-testid="minus-button"
              disabled={blanksQuantity === 1}
              onClick={() => {
                setBlanksQuantity(blanksQuantity - 1);
              }}>
              {' '}
              <MinusCircleIcon />{' '}
            </IconButton>
            <TextField
              id="blanks-qty"
              value={blanksQuantity}
              removeHelperTextSpace
              fullWidth
              inputProps={{
                'data-testid': 'blanks-qty'
              }}
            />
            <IconButton
              color="blue"
              size="large"
              sx={{mr: '-0.5rem', px: 0}}
              data-testid="plus-button"
              fill
              onClick={() => {
                setBlanksQuantity(blanksQuantity + 1);
              }}>
              {' '}
              <PlusCircleIcon />{' '}
            </IconButton>
          </Box>
        </InputGroup>
        <InputGroup sx={{flex: 1, justifyContent: 'flex-end'}}>
          <Button variant="secondary" onClick={onAddToCart}>
            Add to Cart
          </Button>
        </InputGroup>
      </Box>
      {showAlert && (
        <Alert severity="success">
          Added {blanksQuantity} {selectedSize} to the cart
        </Alert>
      )}
    </Stack>
  );
};

export default EmployeeBlanks;
