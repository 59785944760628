import {
  Box,
  ColorSwatch,
  Stack,
  Toggle,
  Typography
} from '@customink/pigment-react';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import useRelatedStyles from 'hooks/queries/relatedStyles';
import {rgbToHex} from '../../utils/color';
import CurrentSelectedColorContext from '../Context/colorContext';
import ColorSwatches from './ColorSwatches';
import LowOnStockMessage from './LowOnStockMessage';
import OutOfStockMessage from './OutOfStockMessage';
import SizeAvailabilityLink from './SizeAvailabilityLink';
import SizeList from './SizeList';

function ProductColorsAndSizes({
  changeColor,
  colors,
  currentColor,
  hasSingles,
  preloadImage,
  renderSampleForm,
  similarRef,
  singles,
  styleId,
  toggleSingles
}) {
  const {currentSelectedColor, setSimilarAccordionState} = useContext(
    CurrentSelectedColorContext
  );
  const {data} = useRelatedStyles(styleId, currentSelectedColor.id);
  const hasSimilarStyles = data?.alternatives?.length > 0;
  const [color, setColor] = useState(currentColor);
  const singlesEnabledColors = colors.filter((c) => c.singlesEnabled);
  const [swatchColors, setSwatchColors] = useState(
    hasSingles && singles ? singlesEnabledColors : colors
  );
  const [youthSizes, setYouthSizes] = useState([]);
  const [adultSizes, setAdultSizes] = useState([]);
  const oneSize = color.sizes[0]?.name === 'One Size';

  useEffect(() => {
    if (
      hasSingles &&
      singles &&
      !currentColor.singlesEnabled &&
      !renderSampleForm
    ) {
      setColor(colors[0]);
      changeColor(colors[0]);
    }
    setSwatchColors(hasSingles && singles ? singlesEnabledColors : colors);
  }, [singles]);

  useEffect(() => {
    setYouthSizes(color.sizes.filter((size) => size.name.startsWith('Y')));
    setAdultSizes(color.sizes.filter((size) => !size.name.startsWith('Y')));
  }, [color]);

  const handleColorChange = (c) => {
    preloadImage(c);
    setColor(c);
  };

  const scrollToSimilar = () => {
    setSimilarAccordionState(true);
    similarRef.current.scrollIntoView({behavior: 'smooth'});
  };

  const renderColorSwatches = useMemo(
    () => (
      <ColorSwatches
        currentColor={currentColor}
        swatches={swatchColors}
        onSwatchClick={changeColor}
        onSwatchMouseEnter={handleColorChange}
        onSwatchMouseLeave={() => handleColorChange(currentColor)}
        onSwatchTouchStart={changeColor}
      />
    ),
    [swatchColors, currentColor]
  );

  const showLowOnStockWarning = color.stockIssues.includes('has_core_low');
  const showOutOfStockMessage = color.stockIssues.includes('has_core_out');
  const showOnlyOneSizeMessage =
    !showOutOfStockMessage && !showLowOnStockWarning && !oneSize;

  return (
    <>
      <Box pt="0.25rem">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pb: {md: 0, sm: '0.5rem'}
          }}>
          <Typography variant="captionLarge" component="h6" pt={0}>
            Colors:
          </Typography>
          {hasSingles && (
            <Box sx={{display: 'inline-flex', gap: '0.625rem'}}>
              <Typography variant="captionRegular">
                Ordering fewer than 6
              </Typography>
              <Toggle
                data-testid="singles-toggle"
                size="small"
                checked={singles}
                onChange={toggleSingles}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
            paddingTop: {md: '0.5rem', sm: 0}
          }}>
          {renderColorSwatches}
        </Box>
      </Box>
      <Stack gap="0.5rem">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem'
          }}>
          {!oneSize && (
            <Typography sx={{p: 0}} variant="captionLarge" component="h6">
              Sizes Available in:
            </Typography>
          )}
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              gap: '0.3125rem'
            }}>
            {!oneSize && (
              <ColorSwatch
                sx={{height: '1.125rem', width: '1.125rem'}}
                key={`${color.name} - ${color.id}`}
                color={rgbToHex(color.rgb)}
                trimRgb={rgbToHex(color.trimRgb)}
                displayOnly
              />
            )}
            <Typography variant="captionRegular">{color.name}</Typography>
          </Box>
        </Box>
        <SizeList sizes={youthSizes.concat(adultSizes)} oneSize={oneSize} />
        {showOutOfStockMessage && (
          <OutOfStockMessage
            hasSimilarStyles={hasSimilarStyles}
            onAlternativesLinkClick={scrollToSimilar}
            oneSize={oneSize}
          />
        )}
        {showLowOnStockWarning && <LowOnStockMessage oneSize={oneSize} />}
        {showOnlyOneSizeMessage && (
          <SizeAvailabilityLink text="Check sizes for all colors" />
        )}
      </Stack>
    </>
  );
}

export default ProductColorsAndSizes;
