import {
  Box,
  IconButton,
  InfoCircleIcon,
  Typography
} from '@customink/pigment-react';
import React, {useContext} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import QuoteTooltip from 'algolia_listing_pages/QuoteTooltip';
import ProductContext from 'product_details_page/Context/productContext';
import rollbar from 'src/vendor/rollbar';

const PlaPricing = () => {
  const {plaPricing} = useContext(ProductContext);

  if (!plaPricing || !plaPricing.price || !plaPricing.quantity) {
    return null;
  }

  return (
    <Box sx={{textAlign: 'center'}}>
      <Typography variant="bodyLong1">
        {plaPricing.price} for an order of {plaPricing.quantity}
      </Typography>
      <QuoteTooltip
        backColor={plaPricing.backColor}
        frontColor={plaPricing.frontColor}
        onTooltipOpen={() => {
          window.dataLayer.push({
            event: 'interaction',
            interaction_action: 'pricing details',
            interaction_category: 'product details page',
            interaction_label: 'pla pricing'
          });
        }}
        quantity={plaPricing.quantity}
        sx={{
          height: '1rem',
          marginLeft: '0.25rem',
          marginTop: '-1px',
          width: '1rem'
        }}>
        <IconButton color="secondary" size="small">
          <InfoCircleIcon fontSize="inherit" />
        </IconButton>
      </QuoteTooltip>
    </Box>
  );
};

export default withErrorBoundary(PlaPricing, {
  fallback: '',
  onError: (error, componentStack) => {
    rollbar.error(error, componentStack);
  }
});
