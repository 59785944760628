import {Box, Link, Typography} from '@customink/pigment-react';
import React from 'react';
import SizeAvailabilityLink from './SizeAvailabilityLink';

const OutOfStockMessage = ({
  hasSimilarStyles,
  onAlternativesLinkClick,
  oneSize
}) => {
  return (
    <Box sx={{lineHeight: '1rem'}}>
      <Typography variant="captionRegular">
        We&apos;re out of some sizes in this color.{' '}
      </Typography>
      {!oneSize && (
        <SizeAvailabilityLink text="Check what's available in other colors" />
      )}
      {hasSimilarStyles && (
        <Typography variant="captionRegular">
          {' '}
          or try a{' '}
          <Link id="alternativesLink" onClick={onAlternativesLinkClick}>
            different style.
          </Link>
        </Typography>
      )}
    </Box>
  );
};

export default OutOfStockMessage;
