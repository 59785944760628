import {Box, MinusIcon, PlusIcon} from '@customink/pigment-react';
import * as React from 'react';

export default function CustomExpandIcon() {
  return (
    <Box
      sx={{
        '.collapsIconWrapper': {
          display: 'flex'
        },
        '.expandIconWrapper': {
          display: 'none'
        },
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none'
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'flex'
        }
      }}>
      <div className="expandIconWrapper">
        <MinusIcon />
      </div>
      <div className="collapsIconWrapper">
        <PlusIcon />
      </div>
    </Box>
  );
}
