import {Box, Divider, Link, Typography} from '@customink/pigment-react';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import useBreakpoint from 'hooks/useBreakpoint';
import useImagePreloader from 'hooks/useImagePreloader';
import * as urlSetup from 'src/utils/url';
import rollbar from 'src/vendor/rollbar';
import {
  trackOrderFewerThanSixEvent,
  trackPreviewUploadDesignDisplayed
} from 'utils/metrics/productDetailsMetrics';
import {
  getColorImage,
  getIxParams,
  getYdhParams,
  mmsImageFingerprint
} from '../../utils/images';
import postMessageForLab from '../../utils/labMessage';
import allowSettingCustomDesignPreview from '../../utils/previewUpload';
import CurrentSelectedColorContext from '../Context/colorContext';
import EmployeeBlanks from '../EmployeeBlanks';
import ProductColorsAndSizes from '../ProductColorsAndSizes';
import DeliveryOptions from './DeliveryOptions';
import ElevateSuperRushDelivery from './ElevateSuperRushDelivery';
import PLAForm from './PLAForm';
import PlaPricing from './PlaPricing';
import ProductCarousel from './ProductCarousel';
import ProductCTA from './ProductCTA';
import ProductDescription from './ProductDescription';
import ProductDetails from './ProductDetails';
import ProductHeader from './ProductHeader';
import RequestSampleTooltip from './RequestSampleTooltip';

const ProductHero = ({
  blanksOrdering,
  checkoutAction,
  colors,
  decoration_method: decorationMethod,
  designPreviewEnabledCategoryIds,
  hasSingles,
  id,
  iframed,
  mainImage,
  material,
  preferSingles,
  primaryCategoryId,
  renderSampleForm,
  reviewRef,
  sampleLinkClass,
  sampleLinkHref,
  sampleOrderType,
  samplePrice,
  sampleQuote,
  sampleQuoteCid,
  selectedColor,
  similarRef,
  sizingText,
  sizingUrl,
  ttarpNumber
}) => {
  const {currentSelectedColor, setCurrentSelectedColor} = useContext(
    CurrentSelectedColorContext
  );
  const [currentColor, setCurrentColor] = useState(selectedColor);
  const [singles, setSingles] = useState(preferSingles);
  const sizes = currentColor?.sizes.filter((size) => size.status === 'active');
  const sizesList = sizes?.map((size) => size.conciseName);
  const isPreviewUploadTestEnabled = allowSettingCustomDesignPreview(
    iframed,
    designPreviewEnabledCategoryIds,
    primaryCategoryId
  );

  const customDesignPreview = isPreviewUploadTestEnabled
    ? ProductCatalog.LocalStorage.read('custom_design_preview')
    : null;
  const [defaultDesignPreview, setDefaultDesignPreview] = useState(() =>
    isPreviewUploadTestEnabled
      ? ProductCatalog.LocalStorage.read('default_design_preview')
      : null
  );
  const breakpoint = useBreakpoint();
  const preloadImage = useImagePreloader();
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    }
  });

  useEffect(() => {
    if (isPreviewUploadTestEnabled)
      ProductCatalog.LocalStorage.write(
        'default_design_preview',
        defaultDesignPreview || null
      );
  }, [defaultDesignPreview]);

  useEffect(() => {
    if (isPreviewUploadTestEnabled && customDesignPreview)
      trackPreviewUploadDesignDisplayed();
  }, [customDesignPreview, isPreviewUploadTestEnabled]);

  const updateUrl = (color) => {
    if (color.id) {
      let url = window.location.href;
      url = urlSetup.setQueryParam('PK', color.id, url);
      window.history.replaceState({}, '', url);
    }
  };

  const updateDataLayer = (value) => {
    const swatchData =
      document.querySelector('.pc-Style-dataLayer')?.dataset || {};
    if (value) {
      swatchData.variant = value.name;
      swatchData.colorid = value.id;
      swatchData.colorurl = `${urlSetup.baseUrl()}?PK=${value.id}`;
      swatchData.colorimage = `https://mms-images.out.customink.com/mms/images/catalog/colors/${value.id}/views/alt/front_small_extended.png`;
    }
    return ProductCatalog.gtmEvent(
      'detailImpression',
      'detailImpression',
      swatchData
    );
  };

  const changeColor = (value) => {
    if (value) {
      setCurrentColor(value);
      setCurrentSelectedColor(value);
      updateUrl(value);
      updateDataLayer(value);
    }
  };

  document.addEventListener('DOMContentLoaded', postMessageForLab('domReady'));

  const imageFingerprint = mmsImageFingerprint({
    style_card_image_url: mainImage[currentColor.id].src
  });

  const buildColorImage = (color, width = 412) => {
    const ixParams = getIxParams(
      {},
      primaryCategoryId,
      mainImage[color.id].src
    );
    const ydhParams = getYdhParams(mainImage[color.id].src);
    ixParams.ixw = width;
    return getColorImage(
      color.id,
      {
        decoration_method: decorationMethod,
        imageFingerprint,
        material,
        primary_category_id: primaryCategoryId,
        style_card_image_url: mainImage[color.id].src
      },
      imageFingerprint,
      {...ixParams, ...ydhParams},
      false,
      iframed,
      true,
      false,
      customDesignPreview,
      setDefaultDesignPreview,
      defaultDesignPreview,
      designPreviewEnabledCategoryIds
    );
  };

  const handlePreloadImage = (color) => {
    preloadImage(buildColorImage(color));
  };

  const handleSetSingles = () => {
    setSingles(!singles);
    trackOrderFewerThanSixEvent(!singles);
    let url = window.location.href;
    if (!singles) {
      url = urlSetup.setQueryParam('quote_qty', 'min', url);
    } else {
      url = urlSetup.deleteQueryParam('quote_qty', url);
    }
    window.history.replaceState({}, '', url);
  };

  const handleSizingLink = () => {
    const popopts =
      'width=640,height=700,scrollbars=yes,toolbar=yes,menubar=no,location=yes,resizable=yes';
    window.open(sizingUrl, 'sizinglineup', popopts);
  };

  const handleQuoteLink = () => {
    const popopts =
      'width=400,height=615,scrollbars=yes,toolbar=yes,menubar=no,location=yes,resizable=yes';
    const url = new URL(`${window.location.origin}/quotes`);
    url.searchParams.append('product_id', currentColor.legacyColorId);
    window.open(url, 'quickquote', popopts);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: {md: 'row', sm: 'column'},
        gap: {md: '1rem', sm: '0', xl: '2rem'},
        margin: 'auto',
        marginTop: {lg: '2rem', md: '1.5rem', sm: 0},
        maxWidth: {sm: 'unset', xl: '80.25rem'},
        padding: {md: '0 1rem', sm: '0'}
      }}>
      <ProductCarousel
        buildColorImage={buildColorImage}
        customDesignPreview={customDesignPreview}
      />
      <Box
        sx={{
          display: 'flex',
          flex: {lg: '5.5', sm: '1'},
          flexDirection: 'column',
          gap: '1rem',
          padding: {md: '0.5rem 0 2.5rem 0', sm: '1rem 1rem 0'}
        }}>
        <ProductHeader reviewRef={reviewRef} />
        <ElevateSuperRushDelivery styleId={id} />
        <ProductColorsAndSizes
          colors={colors}
          currentColor={currentColor}
          changeColor={changeColor}
          hasSingles={hasSingles}
          toggleSingles={handleSetSingles}
          singles={singles}
          sizingText={sizingText}
          preloadImage={handlePreloadImage}
          styleId={id}
          similarRef={similarRef}
          renderSampleForm={renderSampleForm}
          breakpoint={breakpoint}
        />
        <Typography variant="captionRegular">
          <Box
            component="span"
            sx={{
              ...(sizingUrl && {
                borderRight: '1px solid #ededed',
                paddingRight: '0.5rem'
              })
            }}>
            {sizingText}
          </Box>
          {sizingUrl && (
            <Link onClick={handleSizingLink} sx={{marginLeft: '0.5rem'}}>
              Fit & Sizing Guide
            </Link>
          )}
        </Typography>
        <ProductCTA
          customDesignPreview={customDesignPreview}
          iframed={iframed}
          isPreviewUploadTestEnabled={isPreviewUploadTestEnabled}
          singles={singles}
          sizes={sizes}
          sizesList={sizesList}
        />
        {renderSampleForm && (
          <PLAForm
            checkoutAction={checkoutAction}
            currentSelectedColor={currentSelectedColor}
            sampleLinkClass={sampleLinkClass}
            sampleLinkHref={sampleLinkHref}
            sampleOrderType={sampleOrderType}
            samplePrice={samplePrice}
            sampleQuote={sampleQuote}
            sampleQuoteCid={sampleQuoteCid}
            sizes={sizes}
            sizesList={sizesList}
          />
        )}
        {!renderSampleForm && <PlaPricing />}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0.5rem',
            justifyContent: {md: 'center', sm: 'flex-start'}
          }}>
          <RequestSampleTooltip ttarpNumber={ttarpNumber}>
            <span>
              <Link
                sx={{alignSelf: 'center', lineHeight: '1.5rem'}}
                variant="captionRegular">
                Request a Sample
              </Link>
            </span>
          </RequestSampleTooltip>
          <Divider orientation="vertical" flexItem />
          <span>
            <Link
              data-testid="get-quote"
              sx={{lineHeight: '1.5rem'}}
              variant="captionRegular"
              onClick={handleQuoteLink}>
              Get a Quote
            </Link>
          </span>
        </Box>
        <ProductDescription />
        <ProductDetails />
        <DeliveryOptions />
        {blanksOrdering && (
          <EmployeeBlanks currentColor={currentColor} availableSizes={sizes} />
        )}
      </Box>
    </Box>
  );
};

const ProductHeroWithErrorBoundary = withErrorBoundary(ProductHero, {
  fallback: <div>Something went wrong!</div>,
  onError: (error, componentStack) => {
    rollbar.error(error, componentStack);
  }
});

export default ProductHeroWithErrorBoundary;
