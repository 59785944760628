import {useQuery} from 'react-query';

const fetchRelatedStyles = async (styleId, colorId) => {
  const response = await fetch(
    `/products/styles/${styleId}/related_styles.json?PK=${colorId}`
  );
  if (!response.ok) {
    throw new Error('Failed to fetch related styles', {
      colorId,
      response,
      styleId
    });
  }
  return response.json();
};

const useRelatedStyles = (styleId, colorId, options = {}) => {
  return useQuery({
    queryFn: () => fetchRelatedStyles(styleId, colorId),
    queryKey: ['related_styles', styleId, colorId],
    ...options
  });
};

export default useRelatedStyles;
