import React from 'react';
import designTokens from '@customink/design-tokens/build/ci_light/js/designTokens';
import {
  Box,
  BoxIcon,
  LightningIcon,
  TruckFastIcon,
  Typography
} from '@customink/pigment-react';

const DeliveryIcon = ({variant}) => {
  if (variant === 'superRush') {
    return <LightningIcon data-testid="delivery-info-lightning-icon" fontSize="medium" />;
  }

  if (variant === 'rush') {
    return <TruckFastIcon data-testid="delivery-info-truck-icon" fontSize="medium" />;
  }

  return <BoxIcon data-testid="delivery-info-box-icon" fontSize="medium" />;
};

const DELIVERY_LABEL_TEXT = {
  superRush: 'Super Rush',
  rush: 'Rush Delivery',
  standard: 'FREE Delivery'
};

const DeliveryInfo = ({variant, dateStr}) => {
  return (
    <Box
      data-testid="delivery-info-wrap"
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        gap: '0.5rem',
        color: designTokens.color.gray.slateDefault.value,
      }}>
      <Box
        sx={{
          display: 'inline-flex',
          borderRadius: '3rem',
          backgroundColor: designTokens.color.gray.accent.value,
          padding: '0.5rem',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <DeliveryIcon variant={variant} />
      </Box>
      <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Typography
          data-testid="delivery-info-text"
          sx={{
            fontSize: '0.875rem',
            lineWeight: '125%'
          }}>
          {DELIVERY_LABEL_TEXT[variant] || DELIVERY_LABEL_TEXT['regular']}
        </Typography>
        <Typography
          data-testid="delivery-info-date"
          sx={{
            fontSize: '0.8125rem',
            lineWeight: '125%'
          }}>
          {dateStr}
        </Typography>
      </Box>
    </Box>
  );
};

export default DeliveryInfo;
