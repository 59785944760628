import parse from '@customink/html-react-parser';
import {Link, Typography} from '@customink/pigment-react';
import {useTheme} from '@mui/material/styles';
import React, {useContext} from 'react';
import {withErrorBoundary} from 'react-error-boundary';
import useProductDeliveryOptions from 'hooks/queries/productDeliveryOptions';
import ProductContext from 'product_details_page/Context/productContext';
import ProductDetailsRow from 'product_details_page/ProductDetailsRow';
import rollbar from 'src/vendor/rollbar';

const DeliveryOptions = () => {
  const theme = useTheme();
  const {id: styleId} = useContext(ProductContext);
  const {deliveryText, rushDeliveryText} = useProductDeliveryOptions(styleId);

  return (
    <ProductDetailsRow
      title="Delivery Options"
      accordionSummary="Delivery Options"
      accordionWrapperSx={{px: 0}}
      useAccordionAlways>
      <ul>
        <li>
          <Typography variant="bodyLong1">
            <strong>Free </strong>
          </Typography>
          <Typography variant="bodyLong1">{parse(deliveryText)}</Typography>
        </li>
        {rushDeliveryText?.length > 0 && (
          <li>
            <Typography variant="bodyLong1">
              {parse(rushDeliveryText)}
            </Typography>
          </li>
        )}
        <li>
          <Typography
            variant="bodyLong1"
            component="span"
            sx={{
              color: theme.palette.red.dark,
              fontWeight: theme.typography.fontWeightBold
            }}>
            SHIP TO MULTIPLE ADDRESSES
          </Typography>
          <Typography variant="bodyLong1" component="span">
            - Flat rate shipping is $9.95 per US address
          </Typography>
          <br />
          <Link
            variant="bodyLong1"
            sx={{fontWeight: 'normal', textDecoration: 'underline'}}
            className="pc-Style-BssAwareness-link"
            href="#ship-to-multiple-addresses"
            aria-label="Learn more about shipping to multiple addresses"
            data-testid="ship-to-multiple-addresses">
            Learn More
          </Link>
        </li>
      </ul>
    </ProductDetailsRow>
  );
};

const DeliveryOptionsWithErrorBoundary = withErrorBoundary(DeliveryOptions, {
  fallback: <div>Something went wrong!</div>,
  onError: (error, componentStack) => {
    rollbar.error(error, componentStack);
  }
});

export default DeliveryOptionsWithErrorBoundary;
