import parse from '@customink/html-react-parser';
import {Link, Typography} from '@customink/pigment-react';
import React, {useState} from 'react';
import ProductDetailsRow from './ProductDetailsRow';

const PRODUCT_INFO_LIMIT = 900;

const MoreInfo = ({name, productInfo}) => {
  const [expanded, setExpanded] = useState(false);

  const isProductInfoTooLong = productInfo.length > PRODUCT_INFO_LIMIT;
  const showEllipsis = isProductInfoTooLong && !expanded;
  let displayProductInfo = parse(productInfo);

  if (isProductInfoTooLong) {
    displayProductInfo = expanded
      ? productInfo
      : productInfo.slice(0, PRODUCT_INFO_LIMIT);
  }

  return (
    <ProductDetailsRow accordionSummary="More Information" title={name}>
      <Typography variant="bodyLong1">
        {parse(displayProductInfo)}
        {showEllipsis && '...'}
        {isProductInfoTooLong && (
          <Link
            button
            data-testid="readMoreToggle"
            variant="buttonSm"
            onClick={() => setExpanded(!expanded)}
            sx={{paddingBottom: '2px', paddingLeft: '0.25rem'}}>
            {expanded ? 'Read Less' : 'Read More'}
          </Link>
        )}
      </Typography>
    </ProductDetailsRow>
  );
};

export default MoreInfo;
