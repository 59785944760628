import {Box, Typography} from '@customink/pigment-react';
import React from 'react';
import SizeAvailabilityLink from './SizeAvailabilityLink';

const LowOnStockMessage = ({oneSize}) => {
  return (
    <Box sx={{lineHeight: '1rem'}}>
      <Typography variant="captionRegular">
        You have great taste! Order soon, this color is going fast!&nbsp;
        {!oneSize && <SizeAvailabilityLink text="Check sizes for all colors" />}
      </Typography>
    </Box>
  );
};

export default LowOnStockMessage;
