import rollbar from '../src/vendor/rollbar';

const addColorToStylePath = (stylePath, colorId) => {
  try {
    const url = new URL(`${window.location.origin}${stylePath}`);
    if (colorId) {
      const params = new URLSearchParams(url.search);
      params.set('PK', colorId);
      url.search = params.toString();
    }
    return url.toString();
  } catch (error) {
    rollbar.error(
      'Error setting up product link for related styles on PDP',
      error,
      {colorId, path: stylePath}
    );
    return null;
  }
};

export default addColorToStylePath;
