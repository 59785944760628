import {Box, Divider, Typography} from '@customink/pigment-react';
import {styled} from '@customink/pigment-react/lib/themeCustomink';
import React from 'react';

const ListItem = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'oneSize'
})(({oneSize}) => ({
  padding: oneSize ? 0 : '0 0.25rem'
}));

const InactiveListItem = styled(ListItem)({
  '&:before': {
    borderTop: '1px solid red',
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'rotate(-25deg)'
  },
  color: '#c6c6c6',
  position: 'relative'
});

const SizeList = ({oneSize = false, sizes}) => {
  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5rem'
      }}>
      {sizes.map((size, index) => (
        <React.Fragment key={size.name}>
          {index > 0 && <Divider orientation="vertical" flexItem />}
          {size.status !== 'inactive' ? (
            <ListItem variant="bodyShort1" oneSize={oneSize}>
              {size.conciseName}
            </ListItem>
          ) : (
            <InactiveListItem variant="bodyShort1" oneSize={oneSize}>
              {size.conciseName}
            </InactiveListItem>
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SizeList;
