import {ColorSwatch} from '@customink/pigment-react';
import React from 'react';
import {rgbToHex} from '../../utils/color';

const ColorSwatches = ({
  currentColor,
  onSwatchClick = () => {},
  onSwatchMouseEnter = () => {},
  onSwatchMouseLeave = () => {},
  onSwatchTouchStart = () => {},
  swatches
}) => {
  return swatches.map((swatch) => (
    <ColorSwatch
      sx={{
        cursor: 'pointer',
        height: {md: '1.5rem', sm: '2rem'},
        margin: '0.25rem',
        width: {md: '1.5rem', sm: '2rem'}
      }}
      color={rgbToHex(swatch.rgb)}
      trimRgb={rgbToHex(swatch.trimRgb)}
      value={swatch.id}
      key={`${swatch.name} - ${swatch.id}`}
      selected={swatch.id === currentColor.id}
      onMouseEnter={() => onSwatchMouseEnter(swatch)}
      onMouseLeave={onSwatchMouseLeave}
      onClick={() => onSwatchClick(swatch)}
      onTouchStart={() => onSwatchTouchStart(swatch)}
    />
  ));
};

export default ColorSwatches;
