import {Box, Button} from '@customink/pigment-react';
import {useMobileBreakpoint} from '@customink/pigment-react/lib/utils/useBreakpoint';
import React, {useContext, useEffect} from 'react';
import postMessageForLab from '../../../utils/labMessage';
import ProductContext from '../../Context/productContext';
import SelectProductOrDesignButton from './SelectProductOrDesignButton';

const ProductCTA = ({
  customDesignPreview,
  iframed,
  isPreviewUploadTestEnabled,
  singles,
  sizes,
  sizesList
}) => {
  const {active} = useContext(ProductContext);
  const isMobile = useMobileBreakpoint();

  useEffect(() => {
    if (iframed) {
      postMessageForLab('domReady');
    }
  }, []);

  return (
    <Box
      sx={{
        ...(isMobile && {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          bottom: 0,
          boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.25)',
          left: 0,
          padding: '0.5rem 1rem',
          position: 'fixed',
          width: '100%',
          zIndex: 10200
        })
      }}>
      {active ? (
        <div className="pc-Style-hero-cta">
          <SelectProductOrDesignButton
            customDesignPreview={customDesignPreview}
            iframed={iframed}
            isPreviewUploadTestEnabled={isPreviewUploadTestEnabled}
            singles={singles}
            sizes={sizes}
            sizesList={sizesList}
          />
        </div>
      ) : (
        <Button fullWidth disabled>
          Out of Stock
        </Button>
      )}
    </Box>
  );
};

export default ProductCTA;
