import {useState} from 'react';

const useImagePreloader = () => {
  const [images, _] = useState({});

  function preloadImage(colorUrls) {
    if (images[colorUrls.src]) {
      return;
    }
    const image = new Image();
    image.src = colorUrls.src;
    image.srcset = colorUrls.srcset;
    images[colorUrls.src] = image;
  }

  return preloadImage;
};

export default useImagePreloader;
