import {useQuery} from 'react-query';

const TP_API_KEY = process.env.TRUST_PILOT_API_KEY;
const TP_BU_ID = process.env.TRUST_PILOT_BU_ID;
const BASE_URL = `https://api.trustpilot.com/v1/product-reviews/business-units/${TP_BU_ID}?apikey=${TP_API_KEY}`;

const fetchProductReviews = async (skuList) => {
  const fetchUrl = `${BASE_URL}&sku=${skuList}`;
  const response = await fetch(fetchUrl);

  if (!response.ok) {
    throw new Error('Failed to fetch trustpilot product reviews', {
      response,
      skuList
    });
  }
  return response.json();
};

const useTrustPilotProductReviews = (skuList) => {
  return useQuery({
    queryFn: () => fetchProductReviews(skuList),
    queryKey: ['tp_product_reviews', skuList]
  });
};

export default useTrustPilotProductReviews;
