import {ArrowUpIcon, Button, Typography} from '@customink/pigment-react';
import React, {useContext} from 'react';
import * as urlSetup from '../../../src/utils/url';
import labLink from '../../../utils/labLink';
import postMessageForLab from '../../../utils/labMessage';
import CurrentSelectedColorContext from '../../Context/colorContext';
import ProductContext from '../../Context/productContext';

const CustomDesignPreview = () => (
  <>
    <div
      style={{
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        gap: '4px',
        justifyContent: 'center'
      }}>
      <Typography sx={{fontSize: '16px'}}>Continue with Your Design</Typography>
      <Typography sx={{fontSize: '12px'}}>
        Edit your design or add to cart
      </Typography>
    </div>
    <ArrowUpIcon
      fontSize="small"
      sx={{
        flexShrink: 0,
        height: '24px',
        transform: 'rotate(90deg)',
        width: '24px'
      }}
    />
  </>
);

const SelectProductOrDesignButton = ({
  customDesignPreview,
  iframed,
  isPreviewUploadTestEnabled,
  singles,
  sizes,
  sizesList
}) => {
  const {
    categoryInfo,
    decoration,
    id,
    legacyStyleId,
    minQty,
    name,
    primaryCategoryId
  } = useContext(ProductContext);

  const {currentSelectedColor: currentColor} = useContext(
    CurrentSelectedColorContext
  );

  const href = labLink(
    currentColor.legacyColorId,
    singles,
    currentColor.loadLabCid,
    legacyStyleId,
    isPreviewUploadTestEnabled
  );

  const handleLabLinkIframe = (e) => {
    e.preventDefault();
    const productInfo = {
      decoration,
      defaultCategoryId: primaryCategoryId,
      minQty,
      name: currentColor.name,
      productId: currentColor.legacyColorId,
      size_range: `${sizes[0].conciseName}-${sizes[sizes.length - 1].conciseName}`,
      sizes: sizesList.join(', ')
    };
    const payload = {
      categoryPath: categoryInfo.path,
      cid: currentColor.loadLabCid,
      colorId: urlSetup.getQueryParam('PK', href),
      data: [
        currentColor.id,
        productInfo,
        id,
        name,
        categoryInfo.id,
        categoryInfo.name
      ],
      event: 'catalog:product-selected',
      keywords: urlSetup.getQueryParam('KW', href),
      singles,
      styleId: id
    };
    postMessageForLab(payload);
  };

  const buttonProps = {
    'data-testid': 'select-product-or-start-design',
    fullWidth: true,
    ...(iframed
      ? {
          'data-href': href,
          href: '#',
          onClick: handleLabLinkIframe
        }
      : {
          href
        }),
    ...(customDesignPreview && {
      sx: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between'
      }
    })
  };
  const buttonValue = () => {
    if (iframed) return 'Select Product';
    if (customDesignPreview) {
      return <CustomDesignPreview />;
    }
    return 'Start Designing';
  };

  return <Button {...buttonProps}>{buttonValue()}</Button>;
};

export default SelectProductOrDesignButton;
